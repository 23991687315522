@font-face {
  font-family: 'StrawberryIcon-Free';
  src:  url('fonts/StrawberryIcon-Free.eot?83lfek');
  src:  url('fonts/StrawberryIcon-Free.eot?83lfek#iefix') format('embedded-opentype'),
    url('fonts/StrawberryIcon-Free.ttf?83lfek') format('truetype'),
    url('fonts/StrawberryIcon-Free.woff?83lfek') format('woff'),
    url('fonts/StrawberryIcon-Free.svg?83lfek#StrawberryIcon-Free') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="czs-"], [class*=" czs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'StrawberryIcon-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.czs-about:before {
  content: "\e900";
}
.czs-about-l:before {
  content: "\e901";
}
.czs-add:before {
  content: "\e902";
}
.czs-airplane:before {
  content: "\e903";
}
.czs-airplane-l:before {
  content: "\e904";
}
.czs-alipay:before {
  content: "\e905";
}
.czs-analysis:before {
  content: "\e906";
}
.czs-android:before {
  content: "\e907";
}
.czs-angle-down-l:before {
  content: "\e908";
}
.czs-angle-left-l:before {
  content: "\e909";
}
.czs-angle-right-l:before {
  content: "\e90a";
}
.czs-angle-up-l:before {
  content: "\e90b";
}
.czs-apple:before {
  content: "\e90c";
}
.czs-arrow-down-l:before {
  content: "\e90d";
}
.czs-arrow-up-l:before {
  content: "\e90e";
}
.czs-baiduwangpan:before {
  content: "\e90f";
}
.czs-bar-chart:before {
  content: "\e910";
}
.czs-bar-chart-l:before {
  content: "\e911";
}
.czs-battery:before {
  content: "\e912";
}
.czs-battery-l:before {
  content: "\e913";
}
.czs-bell:before {
  content: "\e914";
}
.czs-bell-l:before {
  content: "\e915";
}
.czs-bevel:before {
  content: "\e916";
}
.czs-bilibili:before {
  content: "\e917";
}
.czs-bitcoin:before {
  content: "\e918";
}
.czs-blackboard:before {
  content: "\e919";
}
.czs-blackboard-l:before {
  content: "\e91a";
}
.czs-block:before {
  content: "\e91b";
}
.czs-block-l:before {
  content: "\e91c";
}
.czs-bluetooth:before {
  content: "\e91d";
}
.czs-bluetooth-l:before {
  content: "\e91e";
}
.czs-board:before {
  content: "\e91f";
}
.czs-board-l:before {
  content: "\e920";
}
.czs-book:before {
  content: "\e921";
}
.czs-book-l:before {
  content: "\e922";
}
.czs-bookmark:before {
  content: "\e923";
}
.czs-bookmark-l:before {
  content: "\e924";
}
.czs-box:before {
  content: "\e925";
}
.czs-box-l:before {
  content: "\e926";
}
.czs-briefcase:before {
  content: "\e927";
}
.czs-briefcase-l:before {
  content: "\e928";
}
.czs-brush:before {
  content: "\e929";
}
.czs-brush-l:before {
  content: "\e92a";
}
.czs-bug:before {
  content: "\e92b";
}
.czs-bug-l:before {
  content: "\e92c";
}
.czs-building:before {
  content: "\e92d";
}
.czs-building-l:before {
  content: "\e92e";
}
.czs-buy:before {
  content: "\e92f";
}
.czs-buy-l:before {
  content: "\e930";
}
.czs-calculator:before {
  content: "\e931";
}
.czs-calculator-l:before {
  content: "\e932";
}
.czs-calendar:before {
  content: "\e933";
}
.czs-calendar-l:before {
  content: "\e934";
}
.czs-camber:before {
  content: "\e935";
}
.czs-camber-l:before {
  content: "\e936";
}
.czs-camber-o:before {
  content: "\e937";
}
.czs-camera:before {
  content: "\e938";
}
.czs-camera-l:before {
  content: "\e939";
}
.czs-category-l:before {
  content: "\e93a";
}
.czs-certificate:before {
  content: "\e93b";
}
.czs-certificate-l:before {
  content: "\e93c";
}
.czs-chemistry:before {
  content: "\e93d";
}
.czs-chemistry-l:before {
  content: "\e93e";
}
.czs-choose-list-l:before {
  content: "\e93f";
}
.czs-chrome:before {
  content: "\e940";
}
.czs-circle:before {
  content: "\e941";
}
.czs-circle-l:before {
  content: "\e942";
}
.czs-circle-o:before {
  content: "\e943";
}
.czs-clip-l:before {
  content: "\e944";
}
.czs-clock:before {
  content: "\e945";
}
.czs-clock-l:before {
  content: "\e946";
}
.czs-close-l:before {
  content: "\e947";
}
.czs-clothes:before {
  content: "\e948";
}
.czs-clothes-l:before {
  content: "\e949";
}
.czs-cloud:before {
  content: "\e94a";
}
.czs-cloud-download-l:before {
  content: "\e94b";
}
.czs-cloud-l:before {
  content: "\e94c";
}
.czs-cloud-upload-l:before {
  content: "\e94d";
}
.czs-code-branch:before {
  content: "\e94e";
}
.czs-code-file:before {
  content: "\e94f";
}
.czs-code-file-l:before {
  content: "\e950";
}
.czs-code-fork:before {
  content: "\e951";
}
.czs-code-l:before {
  content: "\e952";
}
.czs-coin:before {
  content: "\e953";
}
.czs-coin-l:before {
  content: "\e954";
}
.czs-collection:before {
  content: "\e955";
}
.czs-come-l:before {
  content: "\e956";
}
.czs-command:before {
  content: "\e957";
}
.czs-command-l:before {
  content: "\e958";
}
.czs-commed2:before {
  content: "\e959";
}
.czs-commed2-l:before {
  content: "\e95a";
}
.czs-comment:before {
  content: "\e95b";
}
.czs-comment-l:before {
  content: "\e95c";
}
.czs-computer:before {
  content: "\e95d";
}
.czs-computer-l:before {
  content: "\e95e";
}
.czs-configuration:before {
  content: "\e95f";
}
.czs-configuration-l:before {
  content: "\e960";
}
.czs-control:before {
  content: "\e961";
}
.czs-control-rank:before {
  content: "\e962";
}
.czs-credit-card:before {
  content: "\e963";
}
.czs-credit-card-l:before {
  content: "\e964";
}
.czs-crown:before {
  content: "\e965";
}
.czs-crown-l:before {
  content: "\e966";
}
.czs-css3:before {
  content: "\e967";
}
.czs-cup:before {
  content: "\e968";
}
.czs-cup-l:before {
  content: "\e969";
}
.czs-dashboard:before {
  content: "\e96a";
}
.czs-dashboard-l:before {
  content: "\e96b";
}
.czs-diamond:before {
  content: "\e96c";
}
.czs-diamond-l:before {
  content: "\e96d";
}
.czs-doc-edit:before {
  content: "\e96e";
}
.czs-doc-edit-l:before {
  content: "\e96f";
}
.czs-doc-file:before {
  content: "\e970";
}
.czs-doc-file-l:before {
  content: "\e971";
}
.czs-download-l:before {
  content: "\e972";
}
.czs-dribbble:before {
  content: "\e973";
}
.czs-dropbox:before {
  content: "\e974";
}
.czs-earth:before {
  content: "\e975";
}
.czs-earth-l:before {
  content: "\e976";
}
.czs-eye:before {
  content: "\e977";
}
.czs-eye-l:before {
  content: "\e978";
}
.czs-face:before {
  content: "\e979";
}
.czs-facebook:before {
  content: "\e97a";
}
.czs-face-l:before {
  content: "\e97b";
}
.czs-file:before {
  content: "\e97c";
}
.czs-file-l:before {
  content: "\e97d";
}
.czs-film:before {
  content: "\e97e";
}
.czs-film-l:before {
  content: "\e97f";
}
.czs-fire-l:before {
  content: "\e980";
}
.czs-firewall:before {
  content: "\e981";
}
.czs-firewall-l:before {
  content: "\e982";
}
.czs-folder:before {
  content: "\e983";
}
.czs-folder-l:before {
  content: "\e984";
}
.czs-forum:before {
  content: "\e985";
}
.czs-forum-l:before {
  content: "\e986";
}
.czs-game:before {
  content: "\e987";
}
.czs-game-l:before {
  content: "\e988";
}
.czs-gift:before {
  content: "\e989";
}
.czs-gift-l:before {
  content: "\e98a";
}
.czs-github:before {
  content: "\e98b";
}
.czs-github-logo:before {
  content: "\e98c";
}
.czs-Google:before {
  content: "\e98d";
}
.czs-greatwall:before {
  content: "\e98e";
}
.czs-hacker:before {
  content: "\e98f";
}
.czs-hacker-l:before {
  content: "\e990";
}
.czs-hammer:before {
  content: "\e991";
}
.czs-hammer-l:before {
  content: "\e992";
}
.czs-hand-bevel:before {
  content: "\e993";
}
.czs-hand-button:before {
  content: "\e994";
}
.czs-hande-vertical:before {
  content: "\e995";
}
.czs-hand-gather:before {
  content: "\e996";
}
.czs-hand-grasp:before {
  content: "\e997";
}
.czs-hand-horizontal:before {
  content: "\e998";
}
.czs-hand-pointer:before {
  content: "\e999";
}
.czs-hand-slide:before {
  content: "\e99a";
}
.czs-hand-stop:before {
  content: "\e99b";
}
.czs-hand-touch:before {
  content: "\e99c";
}
.czs-hdmi:before {
  content: "\e99d";
}
.czs-hdmi-l:before {
  content: "\e99e";
}
.czs-headset:before {
  content: "\e99f";
}
.czs-headset-l:before {
  content: "\e9a0";
}
.czs-heart:before {
  content: "\e9a1";
}
.czs-heart-l:before {
  content: "\e9a2";
}
.czs-home:before {
  content: "\e9a3";
}
.czs-home-l:before {
  content: "\e9a4";
}
.czs-html5:before {
  content: "\e9a5";
}
.czs-image:before {
  content: "\e9a6";
}
.czs-image-l:before {
  content: "\e9a7";
}
.czs-inbox:before {
  content: "\e9a8";
}
.czs-inbox-l:before {
  content: "\e9a9";
}
.czs-Instagram:before {
  content: "\e9aa";
}
.czs-key:before {
  content: "\e9ab";
}
.czs-keyboard:before {
  content: "\e9ac";
}
.czs-keyboard-l:before {
  content: "\e9ad";
}
.czs-key-l:before {
  content: "\e9ae";
}
.czs-label-info:before {
  content: "\e9af";
}
.czs-label-info-l:before {
  content: "\e9b0";
}
.czs-laptop:before {
  content: "\e9b1";
}
.czs-laptop-l:before {
  content: "\e9b2";
}
.czs-layers:before {
  content: "\e9b3";
}
.czs-layout-grid:before {
  content: "\e9b4";
}
.czs-layout-grids:before {
  content: "\e9b5";
}
.czs-layout-list:before {
  content: "\e9b6";
}
.czs-light:before {
  content: "\e9b7";
}
.czs-light-flash-l:before {
  content: "\e9b8";
}
.czs-light-l:before {
  content: "\e9b9";
}
.czs-lightning:before {
  content: "\e9ba";
}
.czs-lightning-l:before {
  content: "\e9bb";
}
.czs-link-l:before {
  content: "\e9bc";
}
.czs-linux:before {
  content: "\e9bd";
}
.czs-list-clipboard:before {
  content: "\e9be";
}
.czs-list-clipboard-l:before {
  content: "\e9bf";
}
.czs-location:before {
  content: "\e9c0";
}
.czs-location-l:before {
  content: "\e9c1";
}
.czs-lock:before {
  content: "\e9c2";
}
.czs-lock-l:before {
  content: "\e9c3";
}
.czs-map:before {
  content: "\e9c4";
}
.czs-map-l:before {
  content: "\e9c5";
}
.czs-medal:before {
  content: "\e9c6";
}
.czs-medal-l:before {
  content: "\e9c7";
}
.czs-menu-l:before {
  content: "\e9c8";
}
.czs-message:before {
  content: "\e9c9";
}
.czs-message-l:before {
  content: "\e9ca";
}
.czs-microchip:before {
  content: "\e9cb";
}
.czs-microchip-l:before {
  content: "\e9cc";
}
.czs-microphone:before {
  content: "\e9cd";
}
.czs-microphone-l:before {
  content: "\e9ce";
}
.czs-microsoft:before {
  content: "\e9cf";
}
.czs-mobile:before {
  content: "\e9d0";
}
.czs-mobile-l:before {
  content: "\e9d1";
}
.czs-moments:before {
  content: "\e9d2";
}
.czs-money:before {
  content: "\e9d3";
}
.czs-moon:before {
  content: "\e9d4";
}
.czs-moon-l:before {
  content: "\e9d5";
}
.czs-more:before {
  content: "\e9d6";
}
.czs-mouse:before {
  content: "\e9d7";
}
.czs-mouse-l:before {
  content: "\e9d8";
}
.czs-music:before {
  content: "\e9d9";
}
.czs-music-file:before {
  content: "\e9da";
}
.czs-music-file-l:before {
  content: "\e9db";
}
.czs-music-l:before {
  content: "\e9dc";
}
.czs-music-note:before {
  content: "\e9dd";
}
.czs-music-note-l:before {
  content: "\e9de";
}
.czs-nail:before {
  content: "\e9df";
}
.czs-nail-l:before {
  content: "\e9e0";
}
.czs-network:before {
  content: "\e9e1";
}
.czs-network-l:before {
  content: "\e9e2";
}
.czs-new:before {
  content: "\e9e3";
}
.czs-new-l:before {
  content: "\e9e4";
}
.czs-newspaper:before {
  content: "\e9e5";
}
.czs-newspaper-l:before {
  content: "\e9e6";
}
.czs-operation:before {
  content: "\e9e7";
}
.czs-operation-l:before {
  content: "\e9e8";
}
.czs-out-l:before {
  content: "\e9e9";
}
.czs-pad:before {
  content: "\e9ea";
}
.czs-pad-l:before {
  content: "\e9eb";
}
.czs-paper:before {
  content: "\e9ec";
}
.czs-paper-l:before {
  content: "\e9ed";
}
.czs-paper-plane:before {
  content: "\e9ee";
}
.czs-paper-plane-l:before {
  content: "\e9ef";
}
.czs-pause-l:before {
  content: "\e9f0";
}
.czs-paypal:before {
  content: "\e9f1";
}
.czs-pen:before {
  content: "\e9f2";
}
.czs-pen-write:before {
  content: "\e9f3";
}
.czs-people:before {
  content: "\e9f4";
}
.czs-phone:before {
  content: "\e9f5";
}
.czs-phone-l:before {
  content: "\e9f6";
}
.czs-pinterest:before {
  content: "\e9f7";
}
.czs-play-l:before {
  content: "\e9f8";
}
.czs-plugin:before {
  content: "\e9f9";
}
.czs-pokemon-ball:before {
  content: "\e9fa";
}
.czs-port:before {
  content: "\e9fb";
}
.czs-port-l:before {
  content: "\e9fc";
}
.czs-printer:before {
  content: "\e9fd";
}
.czs-printer-l:before {
  content: "\e9fe";
}
.czs-product:before {
  content: "\e9ff";
}
.czs-program:before {
  content: "\ea00";
}
.czs-program-l:before {
  content: "\ea01";
}
.czs-qq:before {
  content: "\ea02";
}
.czs-qrcode-l:before {
  content: "\ea03";
}
.czs-quote-left:before {
  content: "\ea04";
}
.czs-quote-right:before {
  content: "\ea05";
}
.czs-qzone:before {
  content: "\ea06";
}
.czs-raspberry:before {
  content: "\ea07";
}
.czs-raspberry-l:before {
  content: "\ea08";
}
.czs-read:before {
  content: "\ea09";
}
.czs-read-l:before {
  content: "\ea0a";
}
.czs-red-envelope:before {
  content: "\ea0b";
}
.czs-renew:before {
  content: "\ea0c";
}
.czs-right-clipboard:before {
  content: "\ea0d";
}
.czs-right-clipboard-l:before {
  content: "\ea0e";
}
.czs-robot:before {
  content: "\ea0f";
}
.czs-robot2:before {
  content: "\ea10";
}
.czs-robot2-l:before {
  content: "\ea11";
}
.czs-robot-l:before {
  content: "\ea12";
}
.czs-rocket:before {
  content: "\ea13";
}
.czs-rocket-l:before {
  content: "\ea14";
}
.czs-rollerbrush:before {
  content: "\ea15";
}
.czs-rollerbrush-l:before {
  content: "\ea16";
}
.czs-router:before {
  content: "\ea17";
}
.czs-router-l:before {
  content: "\ea18";
}
.czs-rss:before {
  content: "\ea19";
}
.czs-ruler:before {
  content: "\ea1a";
}
.czs-ruler-l:before {
  content: "\ea1b";
}
.czs-save:before {
  content: "\ea1c";
}
.czs-save-l:before {
  content: "\ea1d";
}
.czs-scan-l:before {
  content: "\ea1e";
}
.czs-scissors:before {
  content: "\ea1f";
}
.czs-sdcard:before {
  content: "\ea20";
}
.czs-sdcard-l:before {
  content: "\ea21";
}
.czs-search-l:before {
  content: "\ea22";
}
.czs-server:before {
  content: "\ea23";
}
.czs-server-l:before {
  content: "\ea24";
}
.czs-servers:before {
  content: "\ea25";
}
.czs-setting:before {
  content: "\ea26";
}
.czs-setting-l:before {
  content: "\ea27";
}
.czs-share:before {
  content: "\ea28";
}
.czs-shield:before {
  content: "\ea29";
}
.czs-shield-l:before {
  content: "\ea2a";
}
.czs-shop:before {
  content: "\ea2b";
}
.czs-shop-l:before {
  content: "\ea2c";
}
.czs-shopping-cart:before {
  content: "\ea2d";
}
.czs-shopping-cart-l:before {
  content: "\ea2e";
}
.czs-site-folder:before {
  content: "\ea2f";
}
.czs-site-folder-l:before {
  content: "\ea30";
}
.czs-slider-l:before {
  content: "\ea31";
}
.czs-square:before {
  content: "\ea32";
}
.czs-square-l:before {
  content: "\ea33";
}
.czs-square-o:before {
  content: "\ea34";
}
.czs-star:before {
  content: "\ea35";
}
.czs-star-l:before {
  content: "\ea36";
}
.czs-steam:before {
  content: "\ea37";
}
.czs-storage:before {
  content: "\ea38";
}
.czs-storage-l:before {
  content: "\ea39";
}
.czs-sun:before {
  content: "\ea3a";
}
.czs-sun-l:before {
  content: "\ea3b";
}
.czs-sword:before {
  content: "\ea3c";
}
.czs-sword-l:before {
  content: "\ea3d";
}
.czs-tab:before {
  content: "\ea3e";
}
.czs-tab-l:before {
  content: "\ea3f";
}
.czs-tag:before {
  content: "\ea40";
}
.czs-tag-l:before {
  content: "\ea41";
}
.czs-taiji:before {
  content: "\ea42";
}
.czs-talk:before {
  content: "\ea43";
}
.czs-talk-l:before {
  content: "\ea44";
}
.czs-taobao:before {
  content: "\ea45";
}
.czs-telegram:before {
  content: "\ea46";
}
.czs-text-l:before {
  content: "\ea47";
}
.czs-thumbs-up:before {
  content: "\ea48";
}
.czs-thumbs-up-l:before {
  content: "\ea49";
}
.czs-ticket:before {
  content: "\ea4a";
}
.czs-ticket-l:before {
  content: "\ea4b";
}
.czs-time:before {
  content: "\ea4c";
}
.czs-time-l:before {
  content: "\ea4d";
}
.czs-tmall:before {
  content: "\ea4e";
}
.czs-transmission-l:before {
  content: "\ea4f";
}
.czs-transport:before {
  content: "\ea50";
}
.czs-trash:before {
  content: "\ea51";
}
.czs-trash-l:before {
  content: "\ea52";
}
.czs-triangle:before {
  content: "\ea53";
}
.czs-triangle-l:before {
  content: "\ea54";
}
.czs-triangle-o:before {
  content: "\ea55";
}
.czs-truck:before {
  content: "\ea56";
}
.czs-truck-l:before {
  content: "\ea57";
}
.czs-tv:before {
  content: "\ea58";
}
.czs-tv-l:before {
  content: "\ea59";
}
.czs-twitter:before {
  content: "\ea5a";
}
.czs-upload-l:before {
  content: "\ea5b";
}
.czs-usb:before {
  content: "\ea5c";
}
.czs-usb-l:before {
  content: "\ea5d";
}
.czs-user:before {
  content: "\ea5e";
}
.czs-user-l:before {
  content: "\ea5f";
}
.czs-v2ex:before {
  content: "\ea60";
}
.czs-video-camera:before {
  content: "\ea61";
}
.czs-video-camera-l:before {
  content: "\ea62";
}
.czs-video-file:before {
  content: "\ea63";
}
.czs-video-file-l:before {
  content: "\ea64";
}
.czs-vimeo:before {
  content: "\ea65";
}
.czs-volume:before {
  content: "\ea66";
}
.czs-volume-l:before {
  content: "\ea67";
}
.czs-volume-x-l:before {
  content: "\ea68";
}
.czs-warning:before {
  content: "\ea69";
}
.czs-warning-l:before {
  content: "\ea6a";
}
.czs-watch:before {
  content: "\ea6b";
}
.czs-watch-l:before {
  content: "\ea6c";
}
.czs-webcam:before {
  content: "\ea6d";
}
.czs-webcam-l:before {
  content: "\ea6e";
}
.czs-web-edit:before {
  content: "\ea6f";
}
.czs-web-edit-l:before {
  content: "\ea70";
}
.czs-weibo:before {
  content: "\ea71";
}
.czs-weixin:before {
  content: "\ea72";
}
.czs-weixinzhifu:before {
  content: "\ea73";
}
.czs-wifi:before {
  content: "\ea74";
}
.czs-wordpress:before {
  content: "\ea75";
}
.czs-wrench:before {
  content: "\ea76";
}
.czs-wrench-l:before {
  content: "\ea77";
}
.czs-write-l:before {
  content: "\ea78";
}
.czs-x-buy-l:before {
  content: "\ea79";
}
.czs-youtube:before {
  content: "\ea7a";
}
.czs-zhihu:before {
  content: "\ea7b";
}
.czs-zip-folder:before {
  content: "\ea7c";
}
.czs-zip-folder-l:before {
  content: "\ea7d";
}
